import React, { useEffect, useRef, useState } from 'react';
import DashboardLayout from '../../layouts/dashboard/DashboardLayout';
import { colors } from '../../theme/colors';
import Tabs, { BlockTabs } from '../../components/Tabs';
import ChatListItem from '../../components/Sessions/ChatListItem';
import { Caption, Headline } from '../../components/Texts/Texts';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowForwardIcon
} from '@chakra-ui/icons';
import { Clip, Icon, Smiley } from '../../components/Icons';
import ChatBubble from '../../components/Sessions/ChatBubble';
import { getFromUrl, serverRequest } from '../../api/Api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSocket } from '../../hooks/useSocket';
import { capitalize, fileToDataUri, getMediaType } from '../../utils/helper';
import EmojiPicker from 'emoji-picker-react';
import {
  Box,
  Flex,
  Grid,
  Image,
  Input,
  Textarea,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  ModalHeader,
  useToast
} from '@chakra-ui/react';
import { fileSizes } from '../../utils/constants';
import Menu from '../../assets/svg/menu-blue.svg';
import PopoverMenu from '../../components/Popover';
import { PrimaryButton, TextButton } from '../../components/Buttons/Buttons';
import ModalView from '../../components/Modal';

const Sessions = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCaseModalOpen,
    onOpen: onCaseModalOpen,
    onClose: onCaseModalClose
  } = useDisclosure();
  const toast = useToast();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const location: any = useLocation();
  const data = location.state?.case;
  const activeTab = location.state?.activeTab;
  const [caption, setCaption] = useState('');
  const [media, setMedia] = useState<string | null>(null);
  const [sessions, setSessions] = useState([]);
  const [statusFilter, setStatusFilter] = useState('active');
  const [sessionFilter, setSessionFilter] = useState(
    activeTab || 'all sessions'
  );
  const [closeCaseLoading, setCloseCaseLoading] = useState(false);
  const [reason, setReason] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [session, setSession] = useState<any>(data || null);
  const [chatData, setChatData] = useState<any[]>([]);
  const [message, setMessage] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResults, setSearchResults] = useState<any>([]);
  const [showEmoji, toggleEmoji] = useState(false);

  const { socket } = useSocket('sessions');
  socket.clientRegister('patient_message', (data) => {
    addMessageToArray(data);
  });

  const attachRef = useRef<HTMLInputElement | null>(null);

  const addMessageToArray = (message: any) => {
    const newMessages = [...chatData, message];
    setChatData(newMessages as any);
    scrollToBottom();
  };
  useEffect(() => {
    const result = sessions.filter((session: any) => {
      if (sessionFilter === 'all sessions')
        return session?.user?.name
          .toLowerCase()
          .includes(searchKeyword.toLowerCase());
      if (sessionFilter === 'my sessions')
        return session?.case?.user?.name
          .toLowerCase()
          .includes(searchKeyword.toLowerCase());
    });
    setSearchResults(result);
  }, [searchKeyword]);

  const getSessions = async () => {
    let endpoint = `cases/sessions`;
    if (sessionFilter === 'all sessions') {
      endpoint = `cases/sessions/all`;
    }
    try {
      const sessions = await getFromUrl(
        `${endpoint}?stage=${statusFilter.toUpperCase()}`
      );
      if (sessions.status === 401) navigate('/authentication/signin');
      if (sessions !== undefined) {
        setSessions(sessions);
        setSearchResults(sessions);
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const closeCase = async () => {
    setCloseCaseLoading(true);
    setReason('');
    onCaseModalClose();
    try {
      const response = await serverRequest({
        endpoint: `cases/close`,
        method: 'PUT',
        body: {
          caseId: session?.case?._id || session._id,
          reason
        }
      });
      getSessions().catch(() => {
        return;
      });
      setCloseCaseLoading(false);
      if (response.status === 401) navigate('/authentication/signin');
      if (response.status === 400) {
        toast({
          position: 'top',
          title: 'Error',
          description: 'Error closing case',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      } else {
        toast({
          position: 'top',
          title: 'Success',
          description: 'Case closed successfully',
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    setSearchResults([]);
    setSearchKeyword('');
    getSessions().catch((err) => console.error(err));
  }, [statusFilter, sessionFilter]);

  const handleStatusFilter = (filter: string) => {
    setSession(null);
    setStatusFilter(filter);
  };

  const handleSessionFilter = (filter: string) => {
    setSession(null);
    setSessionFilter(filter);
  };

  const cacheChatHistory = (chatHistory: unknown) => {
    const sessions = localStorage.getItem('sessions');
    let sessionsArr;
    if (!sessions) sessionsArr = {};
    if (sessions) sessionsArr = JSON.parse(sessions);
    sessionsArr[session.user.phone] = chatHistory;
    localStorage.setItem('sessions', JSON.stringify(sessionsArr));
  };

  const transformMessages = (messages: object[]): any[] => {
    const transformedMessages = messages.map((message: any) => ({
      id: message.id,
      author: message._vnd.v1.author.name,
      type: message.type,
      direction: message._vnd.v1.direction,
      timestamp: message.timestamp,
      [message.type]: message[message.type]
    }));
    return transformedMessages;
  };

  const getChatHistory = async () => {
    try {
      let endpoint = `webhook/contacts/${session.user.phone}/messages`;
      if (sessionFilter === 'my sessions')
        endpoint = `webhook/contacts/${session.case.user.phone}/messages`;
      const res = await getFromUrl(endpoint);
      if (typeof res === 'object') {
        const chats = transformMessages(res);
        const reversedChat = chats.reverse();
        setChatData(reversedChat);
        cacheChatHistory(reversedChat);
      }
      scrollToBottom();
    } catch (e: any) {
      return;
    }
  };

  const addMessage = async (message: string) => {
    setMessage('');
    const pendingMessage = {
      id: Math.random() * 1000,
      direction: 'outbound',
      to: session.user.phone,
      type: 'text',
      text: {
        body: message
      }
    };
    addMessageToArray(pendingMessage);
    const body = {
      whatsAppId: session.user.phone,
      type: 'text',
      message
    };
    await serverRequest({
      endpoint: 'webhook/messages',
      body,
      method: 'post'
    });
    scrollToBottom();
  };

  const addAttachment = (e: any) => {
    const attachment = e?.target?.files[0];
    if (attachment) {
      setFile(attachment);

      fileToDataUri(attachment).then(async (dataUri: any) => {
        const blob = new Blob([dataUri]);
        const objectUrl = window.URL.createObjectURL(blob);
        setMedia(objectUrl);
      });
      onOpen();
    }
    e.target.value = '';
  };

  const sendAttachment = () => {
    onClose();
    if (file && media) {
      const mediaType = getMediaType(file.type);
      const pendingMessage = {
        id: Math.round(Math.random() * 100000),
        status: 'pending',
        direction: 'outbound',
        to: session.user.phone,
        type: mediaType,
        [mediaType as string]: {
          media: file,
          caption: caption || file.name
        }
      };
      addMessageToArray(pendingMessage);
      const token = localStorage.getItem('access_token');
      const body = new FormData();
      body.append('file', file);
      fetch('https://itunu.cchub.info/api/v1/webhook/media', {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        body
      })
        .then((res) => res.json())
        .then((data) => {
          const mediaId = data.mediaId;
          const body = {
            whatsAppId: session.user.phone,
            type: mediaType,
            mediaId,
            caption
          };
          serverRequest({
            endpoint: 'webhook/messages',
            body,
            method: 'POST'
          }).catch(() => {
            return;
          });
        });
      setCaption('');
      setFile(null);
    }
  };

  useEffect(() => {
    if (session) {
      setChatData([]);
      const cachedSessions = localStorage.getItem('sessions');
      if (cachedSessions) {
        const sessionsArr = JSON.parse(cachedSessions);
        if (sessionsArr[session.user.phone] !== undefined)
          setChatData(sessionsArr[session.user.phone]);
      }
      getChatHistory().catch((err) => console.error(err));
      scrollToBottom();
    }
  }, [session]);

  const scrollToBottom = () => {
    return messagesEndRef.current?.scrollIntoView();
  };

  const handleKeyPress = (key: string) => {
    if (key === 'Enter' && message.trim().length) {
      addMessage(message).catch((err) => console.error(err));
    }
  };

  const canSendMedia = (file: File) => {
    if (file) {
      const mediaType = getMediaType(file?.type);
      if (mediaType === 'document' && file.size > 5000000) return true;
      if (
        (mediaType === 'audio' || mediaType === 'video') &&
        file.size > 16000000
      )
        return true;
      if (mediaType === 'image' && file.size > 5000000) return true;
      if (mediaType === 'sticker' && file.size > 100000) return true;
    }
  };
  return (
    <DashboardLayout pageTitle='Sessions'>
      <Grid gridTemplateColumns='1fr  2.4fr' mt='-54px' pb='108px'>
        <Flex
          flexDirection='column'
          borderRight={`1px solid ${colors.shade}`}
          h='calc(100vh - 84px)'
        >
          <Box
            borderBottomWidth='1px'
            borderBottomColor='shade'
            pt='24px'
            mb='32px'
            ml='30px'
          >
            <Tabs
              tabs={['all sessions', 'my sessions']}
              activeTab={sessionFilter}
              tabAction={handleSessionFilter}
            />
          </Box>
          <Box px='30px'>
            <Input
              placeholder='Type here...'
              fontSize='12px'
              minW='244px'
              h='40px'
              borderRadius='15px'
              borderWidth='0.5px'
              borderColor='gray_200'
              bg='white'
              mb='30px'
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </Box>
          <BlockTabs
            tabs={['active', 'ongoing', 'closed']}
            activeTab={statusFilter}
            tabAction={handleStatusFilter}
            centered
          />
          <Box flex={1} overflowY='scroll' mt='20px' pr='18px'>
            {sessionFilter === 'all sessions' && searchResults.length
              ? searchResults.map((item: any) => {
                  const { _id, user, lastMessage } = item;
                  console.log('ITEM', item?.supervisor);
                  return (
                    <ChatListItem
                      key={_id}
                      name={(user as any)?.name}
                      time={(lastMessage as any)?.lastClientMessageTime}
                      message={(lastMessage as any)?.message}
                      count={0}
                      onClick={() => setSession(item)}
                      active={_id === session?._id}
                    />
                  );
                })
              : null}
            {sessionFilter === 'my sessions' && searchResults.length
              ? searchResults.map((item: any) => {
                  const { _id, case: caseItem } = item;
                  return (
                    <ChatListItem
                      key={_id}
                      name={(caseItem?.user as any)?.name}
                      time={
                        (caseItem?.user?.lastMessage as any)
                          ?.lastClientMessageTime ||
                        (caseItem?.lastMessage as any)?.lastClientMessageTime
                      }
                      message={
                        (caseItem?.user?.lastMessage as any)?.message ||
                        (caseItem?.lastMessage as any)?.message
                      }
                      count={0}
                      onClick={() => setSession(item)}
                      active={_id === session?._id}
                    />
                  );
                })
              : null}
            {!searchResults.length && searchKeyword.length ? (
              <Text color={colors.deep_blue} mt='10px'>
                {`No session found for ${searchKeyword}`}
              </Text>
            ) : null}
          </Box>
        </Flex>
        <Flex flexDirection='column' h='calc(100vh - 84px)'>
          {session && (
            <>
              <Flex
                bg='white'
                h='80px'
                px='44px'
                alignItems='center'
                justifyContent='space-between'
              >
                <Flex bg='white' h='80px' px='44px' alignItems='center'>
                  <Box>
                    <Caption color='gray'>Client</Caption>
                    <Headline size='19px' color='deep_blue' fontWeight={500}>
                      {session?.user?.name}
                    </Headline>
                  </Box>
                  {sessionFilter === 'all sessions' && (
                    <>
                      <ChevronLeftIcon color='deep_blue' ml='30px' mt='20px' />
                      <ChevronRightIcon color='deep_blue' mr='30px' mt='20px' />
                      <Box mr='auto'>
                        <Caption color='gray'>Counsellor</Caption>
                        <Headline
                          size='19px'
                          color='deep_blue'
                          fontWeight={500}
                        >
                          {session?.supervisor
                            ? session?.supervisor?.name
                            : session?.counsellor?.name}
                        </Headline>
                      </Box>
                    </>
                  )}
                </Flex>
                <PopoverMenu
                  trigger={
                    <Image
                      src={Menu}
                      alt={``}
                      width={4}
                      height={16}
                      boxSize={4}
                      cursor='pointer'
                    />
                  }
                  top='85px'
                  left='10'
                  w='234px'
                  placement='right'
                >
                  {(onClose: any) => (
                    <Box fontSize='19px' fontWeight={500} color='gray'>
                      <Flex
                        onClick={() => {
                          onClose();
                          onCaseModalOpen();
                        }}
                        fontSize='19px'
                        py='14px'
                        justifyContent='center'
                        cursor='pointer'
                        color={colors.error}
                      >
                        Close Case
                      </Flex>
                    </Box>
                  )}
                </PopoverMenu>
              </Flex>
              <Box flex={1} overflowY='scroll' py='20px' px='64px'>
                {chatData.map((chat: any, index) => {
                  return (
                    <ChatBubble
                      chat={chat}
                      previousDate={(chatData as any)[index - 1]?.createdAt}
                      key={chat.id}
                      counsellor={
                        session?.supervisor
                          ? session.supervisor
                          : session?.counsellor
                      }
                    />
                  );
                })}
                <Box ref={messagesEndRef} />
              </Box>
              <Flex
                bg='white'
                minH='86px'
                pr='38px'
                pl='18px'
                pt='8px'
                alignItems='flex-start'
              >
                {sessionFilter === 'my sessions' &&
                  statusFilter === 'active' && (
                    <>
                      <Flex
                        height='50px'
                        alignItems='center'
                        mr='28px'
                        cursor='pointer'
                        onClick={() => toggleEmoji(!showEmoji)}
                      >
                        <Smiley />
                      </Flex>
                      <Textarea
                        minH='50px'
                        maxH='150px'
                        flexGrow={1}
                        placeholder='Write a message...'
                        mr='30px'
                        border='none'
                        _focus={{
                          outline: 'none',
                          boxShadow: 'none'
                        }}
                        resize='none'
                        pt='15px'
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e.key)}
                        value={message}
                      ></Textarea>
                      <Flex height='50px' alignItems='center'>
                        <Input
                          type='file'
                          id='attach-file'
                          accept='image/jpeg, image/png,video/mp4,video/3gp,image/webp,text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg,video/3gp'
                          ref={attachRef}
                          display='none'
                          onChange={addAttachment}
                        />
                        <span
                          onClick={() => {
                            if (attachRef.current !== null)
                              attachRef.current.click();
                          }}
                        >
                          <Clip />
                        </span>
                        <Flex
                          boxSize='40px'
                          borderRadius='20px'
                          bg='deep_blue'
                          ml='30px'
                          justifyContent='center'
                          alignItems='center'
                          cursor='pointer'
                          onClick={() => {
                            if (message.trim().length)
                              addMessage(message).catch((err) =>
                                console.error(err)
                              );
                          }}
                        >
                          <Icon name='Send' filled color='#ffffff' />
                        </Flex>
                      </Flex>
                    </>
                  )}
              </Flex>
              {showEmoji ? (
                <EmojiPicker
                  width='100%'
                  height={300}
                  searchDisabled={true}
                  previewConfig={{
                    showPreview: false
                  }}
                  onEmojiClick={({ emoji }) => setMessage(message + emoji)}
                />
              ) : null}
            </>
          )}
        </Flex>
      </Grid>
      <ModalView
        isOpen={isCaseModalOpen}
        onClose={onCaseModalClose}
        bg='gray_50'
        maxW='554px'
        noCloseBtn
      >
        <Box bg='gray_50' px='55px' py='75px' borderRadius='8px'>
          <Headline size='19px' color='gray' mb='28px' textAlign='center'>
            Give us a feedback on this Case (Optional)
          </Headline>
          <Textarea
            bg={colors.white}
            borderRadius='15px'
            border='none'
            cols={10}
            rows={10}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            mb='34px'
            resize='none'
            placeholder='Type here...'
          />
          <PrimaryButton
            label='Proceed'
            background={colors.deep_blue}
            onClick={closeCase}
            isLoading={closeCaseLoading}
          />
          <TextButton label='cancel' mt='24px' onClick={onCaseModalClose} />
        </Box>
      </ModalView>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'2xl'}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />

        <ModalContent>
          {file !== null && canSendMedia(file) && (
            <ModalHeader>{`${capitalize(
              getMediaType(file.type)
            )} size must not be larger than ${
              fileSizes[getMediaType(file.type)]
            }`}</ModalHeader>
          )}
          <ModalCloseButton color={colors.deep_blue} />
          <ModalBody paddingX={12} paddingTop={12}>
            {file &&
              media &&
              (getMediaType(file?.type) === 'image' ||
                getMediaType(file?.type) === 'sticker') && (
                <Image src={media} />
              )}
            {file && media && getMediaType(file?.type) === 'video' && (
              <video src={media} controls />
            )}
            {file && media && getMediaType(file?.type) === 'document' && (
              <Box width={'100%'} bg={colors.deep_blue} borderRadius={10}>
                <Text
                  padding={10}
                  color={colors.white}
                  w={'max-content'}
                  m={'auto'}
                >
                  {file?.name}
                </Text>
              </Box>
            )}
          </ModalBody>
          <ModalFooter display='flex' alignItems={'center'} paddingX={12}>
            {file &&
              ['image', 'document', 'sticker', 'video'].includes(
                getMediaType(file.type)
              ) && (
                <>
                  <Text flex={1} color={colors.deep_blue}>
                    Add Caption
                  </Text>
                  <Input
                    borderColor={colors.deep_blue}
                    flex={3}
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                  />
                </>
              )}
            <Button
              rightIcon={<ArrowForwardIcon />}
              colorScheme='blue'
              variant='solid'
              // flex={1}
              ml={2}
              justifySelf={'flex-end'}
              isDisabled={file !== null && canSendMedia(file)}
              onClick={sendAttachment}
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DashboardLayout>
  );
};
export default Sessions;
