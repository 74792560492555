import { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { base_url } from '../api/Api';

export const useSocketNew = (namespace: string) => {
  const ref = useRef<Socket>();
  const [connected, setConnected] = useState(false);
  const [newMessage, setNewMessage] = useState(null);
  const send = (eventName: string, data: any) => {
    ref.current?.emit(eventName, data);
  };

  const recieve = (client: string, fn: (arg: any) => void) => {
    ref.current?.on(client, (data) => fn(data));
  };

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const socket = io(base_url + '/community', {
      path: '/sockets/',
      transports: ['websocket'],
      auth: {
        Authorization: `Bearer ${token}`
      }
    });

    socket.on('disconnect', () => {
      setConnected(false);
    });

    socket.on('connect', () => {
      setConnected(true);
    });

    socket.on('community_server_to_client', (res) => {
      setNewMessage(res);
    });

    ref.current = socket;

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namespace]);

  return {
    send,
    recieve,
    connected,
    newMessage
  };
};
