import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { getFromUrl, serverRequest } from '../../api/Api';
import { OutlineButton, PrimaryButton } from '../../components/Buttons/Buttons';
import CounsellorsTable from '../../components/Cases/CounsellorsTable';
import DetailsItem from '../../components/Cases/DetailsItem';
import SeverityTag from '../../components/Cases/SeverityTag';
import SymptomsTag from '../../components/Cases/SymptomsTag';
import { SearchInput } from '../../components/Inputs/TextField';
import ModalView from '../../components/Modal';
import { Body, Headline } from '../../components/Texts/Texts';
import DashboardLayout from '../../layouts/dashboard/DashboardLayout';
import { colors } from '../../theme/colors';

interface Indexable {
  [key: string]: any;
}

const Cases = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { id: caseId } = useParams();
  const location: any = useLocation();
  const { items, index } = location.state;
  const [caseItem, setCaseItem] = useState<any>(null);
  const [isSmallerThan1280] = useMediaQuery('(max-width: 1280px)');
  const [counsellors, setCounsellors] = useState([]);
  const [counsellorId, setCounsellorId] = useState('');
  const [pickLoading, setPickLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filter] = useState('');
  const [counsellorsCount, setCounsellorsCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResults, setSearchResults] = useState<any>([]);
  const PAGE_SIZE = 10;
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const getPrev = () => {
    if (index === 0) return null;
    return items[index - 1]._id;
  };
  const getNext = () => {
    if (index === items.length - 1) return null;
    return items[index + 1]._id;
  };
  const getCase = async (id: string) => {
    try {
      const data = await getFromUrl(`cases/${id}`);
      setCaseItem(data);
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const pickCase = async () => {
    setPickLoading(true);
    try {
      const response = await serverRequest({
        method: 'PUT',
        body: null,
        endpoint: `cases/pick/${caseId}`
      });
      setPickLoading(false);
      if (response) {
        navigate(`/sessions`, {
          state: { case: response, activeTab: 'my sessions' }
        });
      }
    } catch (e: any) {
      setPickLoading(false);
    }
  };

  const getCounsellors = async () => {
    try {
      const data = await getFromUrl(
        `users/counsellors?type=ALL&size=${PAGE_SIZE}&page=${currentPage}`
      );
      setCounsellors(data);
      setCounsellorsCount(data.length);
    } catch (e: any) {
      return;
    }
  };

  const assignCase = async () => {
    setAssignLoading(true);
    if (counsellorId) {
      const body = {
        caseId,
        counsellorId
      };
      try {
        const response = await serverRequest({
          method: 'PUT',
          body,
          endpoint: `cases/assign`
        });
        setAssignLoading(false);
        onClose();
        toast({
          position: 'top',
          title: 'Success',
          description: `You've assigned case to ${response.counsellor.name}`,
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      } catch (e: any) {
        setAssignLoading(false);
      }
    }
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const severityColors = {
    mild: colors.gray,
    moderate: colors.accent,
    moderately_severe: colors.warning,
    severe: colors.error
  };

  useEffect(() => {
    if (caseId) getCase(caseId).catch((err) => console.error(err));
  }, []);

  const searchCounsellorsList = async () => {
    if (timeoutId) clearTimeout(timeoutId);
    const searchTimeoutId = setTimeout(async () => {
      try {
        const endpoint = `users/supervisors_counsellors/search?search=${searchKeyword}`;
        const searchResults = await getFromUrl(endpoint);
        setSearchResults(searchResults);
      } catch (e: any) {
        console.error(e.message);
      }
    }, 1000);
    setTimeoutId(searchTimeoutId);
  };

  useEffect(() => {
    if (searchKeyword.length) {
      searchCounsellorsList().catch((err) => console.error(err));
    }
  }, [searchKeyword]);

  useEffect(() => {
    getCounsellors().catch((err) => console.error(err));
  }, [currentPage]);

  return (
    <DashboardLayout pageTitle='Cases'>
      <Box pr={isSmallerThan1280 ? '50px' : '150px'}>
        <Flex
          display='inline-flex'
          onClick={() => navigate('/cases')}
          alignItems='center'
          cursor='pointer'
          mb='40px'
        >
          <ChevronLeftIcon color='deep_blue' h='30px' w='16px' />
          <Body size='14px' textTransform='uppercase' color='deep_blue'>
            Back
          </Body>
        </Flex>
        {caseItem && (
          <Box>
            <SeverityTag type={caseItem.severity.toLowerCase()} />
            <Flex justifyContent='space-between'>
              <Box maxW={isSmallerThan1280 ? '400px' : '550px'} mr='20px'>
                <Headline
                  size='19px'
                  color='deep_blue'
                  fontWeight={700}
                  mt='16px'
                  mb='20px'
                >
                  {caseItem.user.name}
                </Headline>
                {caseItem?.counsellor && (
                  <Grid
                    gridTemplateColumns={`repeat(${
                      isSmallerThan1280 ? '2' : '3'
                    }, 1fr)`}
                    gap='18px'
                  >
                    <GridItem
                      display='flex'
                      justifyContent='space-between'
                      gridColumnGap='14px'
                      marginBottom='20px'
                    >
                      <Box>
                        <DetailsItem
                          label='Counsellor assigned'
                          value={caseItem.counsellor.name || ''}
                        />
                      </Box>
                    </GridItem>
                  </Grid>
                )}
                <Grid
                  gridTemplateColumns={`repeat(${
                    isSmallerThan1280 ? '2' : '3'
                  }, 1fr)`}
                  gap='18px'
                >
                  <GridItem
                    display='flex'
                    justifyContent='space-between'
                    gridColumnGap='14px'
                  >
                    <Box>
                      <DetailsItem
                        label='Gender:'
                        value={caseItem.user.gender || ''}
                      />
                    </Box>
                    <Box>
                      <DetailsItem
                        label='Age:'
                        value={caseItem.user.ageGroup || ''}
                      />
                    </Box>
                  </GridItem>
                  <GridItem>
                    <DetailsItem
                      label='Location:'
                      value={caseItem.user.address}
                    />
                  </GridItem>
                  <GridItem>
                    <DetailsItem
                      label='Employment Status:'
                      value={caseItem.user.employmentStatus}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <DetailsItem
                      label='Phone Number:'
                      value={caseItem.user.phone}
                    />
                  </GridItem>
                </Grid>
                <Flex mt='20px' mb='14px'>
                  <SymptomsTag
                    text={caseItem.symptom}
                    color={
                      (severityColors as Indexable)[
                        caseItem.severity.toLowerCase()
                      ]
                    }
                    mr='16px'
                  />
                </Flex>
                {!caseItem?.isReported && (
                  <>
                    <Body size='16px' color='black' fontWeight={700} mb='5px'>
                      Problem Statement
                    </Body>
                    <Body size='16px' color='black' mb='5px'>
                      {caseItem.problemStatement}
                    </Body>
                  </>
                )}
                {caseItem.isReported && (
                  <>
                    <Body
                      backgroundColor='rgba(255, 113, 113, 0.1)'
                      p='24px'
                      minWidth='495px'
                      pb='79px'
                      borderRadius='5px'
                      mt='32px'
                    >
                      <Body
                        size='16px'
                        color='#FF7171'
                        mb='24px'
                        fontSize='19px'
                        fontWeight={700}
                      >
                        Reason for report:
                      </Body>
                      <Body>{caseItem?.reason}</Body>
                    </Body>
                  </>
                )}
              </Box>
              <Flex
                w='343px'
                flexDirection='column'
                justifyContent='space-between'
              >
                <Flex flexDirection='column'>
                  <PrimaryButton
                    label={caseItem.isReported ? 'Resolve' : 'Pick case'}
                    onClick={pickCase}
                    isLoading={pickLoading}
                    height='55px'
                    background={
                      caseItem.isReported
                        ? '#389583'
                        : (severityColors as Indexable)[
                            caseItem.severity.toLowerCase()
                          ]
                    }
                    mb='10px'
                  />
                  <OutlineButton
                    label={
                      caseItem.isReported
                        ? 'Block'
                        : caseItem.counsellor
                        ? 'Re - Assign'
                        : 'Assign case'
                    }
                    onClick={onOpen}
                    height='55px'
                    color={
                      caseItem.isReported
                        ? '#FF7171'
                        : (severityColors as Indexable)[
                            caseItem.severity.toLowerCase()
                          ]
                    }
                  />
                </Flex>
                <Flex justifyContent='space-between'>
                  {
                    <Flex display='flex' alignItems='center'>
                      <Link
                        to={`/cases/${getPrev()}`}
                        style={{
                          pointerEvents: `${
                            getPrev() === null ? 'none' : 'auto'
                          }`
                        }}
                        state={{ items, index: index - 1 }}
                      >
                        <Button>
                          <ChevronLeftIcon />
                          <Text
                            fontWeight='400'
                            marginLeft='15px'
                            color='#05396B'
                            opacity={getPrev() === null ? '0.3' : '1'}
                          >
                            PREVIOUS
                          </Text>
                        </Button>
                      </Link>
                    </Flex>
                  }
                  {
                    <Flex display='flex' alignItems='center'>
                      <Link
                        to={`/cases/${getNext()}`}
                        style={{
                          pointerEvents: `${
                            getNext() === null ? 'none' : 'auto'
                          }`
                        }}
                        state={{ items, index: index + 1 }}
                      >
                        <Button>
                          <Text
                            fontWeight='400'
                            marginRight='15px'
                            color='#05396B'
                            opacity={getNext() === null ? '0.3' : '1'}
                          >
                            NEXT
                          </Text>
                          <ChevronRightIcon />
                        </Button>
                      </Link>
                    </Flex>
                  }
                </Flex>
              </Flex>
            </Flex>
          </Box>
        )}
      </Box>
      <ModalView isOpen={isOpen} onClose={onClose} maxW='80%' bg='gray_50'>
        <Box maxH='750px' py='50px' px='146px'>
          <Flex alignItems='center' mb='28px'>
            <Headline size='19px' color='deep_blue' mr='auto'>
              Assign Case to a supervisor or Counsellor
            </Headline>
            <SearchInput
              maxW='244px'
              value={searchKeyword}
              onChange={({ target: { value } }) => setSearchKeyword(value)}
              mr='40px'
            />
          </Flex>
          {counsellorsCount && counsellors.length ? (
            <CounsellorsTable
              filterCounsellors={filter}
              counsellors={searchKeyword.length ? searchResults : counsellors}
              searchText={searchKeyword}
              counsellorsCount={counsellorsCount}
              currentPage={currentPage}
              onPageChange={onPageChange}
              scroll
              actionItem={({ counsellorData }: { counsellorData: any }) => (
                <Box
                  boxSize='20px'
                  borderRadius='5px'
                  borderWidth='2px'
                  borderColor='deep_blue'
                  mr='12px'
                  cursor='pointer'
                  bg={
                    counsellorData._id === counsellorId
                      ? 'deep_blue'
                      : 'transparent'
                  }
                  onClick={() => setCounsellorId(counsellorData._id)}
                ></Box>
              )}
            />
          ) : null}
          {searchKeyword.length && !searchResults.length ? (
            <Text mx='auto' w='max-content' color={colors.deep_blue} mt='40px'>
              No data found
            </Text>
          ) : null}
          {searchKeyword.length && !searchResults.length ? null : (
            <Flex justifyContent='center'>
              <PrimaryButton
                label='Assign'
                onClick={assignCase}
                height='55px'
                width='343px'
                background='deep_blue'
                mt='23px'
                mx='auto'
                isLoading={assignLoading}
                disabled={counsellorId === ''}
              />
            </Flex>
          )}
        </Box>
      </ModalView>
    </DashboardLayout>
  );
};

export default Cases;
