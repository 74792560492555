import { io, Socket } from 'socket.io-client';
import { base_url } from '../api/Api';

export class SessionSocket {
  public static instance: SessionSocket;
  socket: Socket;
  private constructor(token: string) {
    this.socket = io(base_url + '', {
      path: '/sessions/sockets',
      transports: ['websocket'],
      auth: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  static build(token: string): SessionSocket {
    if (this.instance != null) {
      return this.instance;
    } else {
      this.instance = new SessionSocket(token);
      return this.instance;
    }
  }

  register(eventName: string, fn: (arg: SessionSocket) => void) {
    this.socket.on(eventName, () => fn(SessionSocket.instance));
  }

  emit<T>(eventName: string, data: T) {
    this.socket.emit(eventName, data);
  }

  clientRegister(client: string, fn: (arg: any) => void) {
    this.socket.on(client, (data) => fn(data));
  }

  disconnect() {
    this.socket.disconnect();
  }
}

export class CommunitySocket {
  public static instance: CommunitySocket;
  socket: Socket;
  private constructor(token: string) {
    this.socket = io(base_url + '/community', {
      path: '/sockets/',
      transports: ['websocket'],
      auth: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  static build(token: string): CommunitySocket {
    if (this.instance != null) {
      return this.instance;
    } else {
      this.instance = new CommunitySocket(token);
      return this.instance;
    }
  }

  register(eventName: string, fn: (arg: CommunitySocket) => void) {
    this.socket.on(eventName, () => fn(CommunitySocket.instance));
  }

  emit<T>(eventName: string, data: T) {
    this.socket.emit(eventName, data);
  }

  clientRegister(client: string, fn: (arg: any) => void) {
    this.socket.on(client, (data) => fn(data));
  }

  disconnect() {
    this.socket.disconnect();
  }
}
