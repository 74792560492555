import { SessionSocket, CommunitySocket } from '../utils/app.socket';

export const useSocket = (namespace: string) => {
  const token = localStorage.getItem('access_token');
  const socket = (() => {
    const soc =
      namespace === 'sessions'
        ? SessionSocket.build(token as string)
        : CommunitySocket.build(token as string);
    soc.register('connect', () => {
      console.log(`Socket connected!`);
    });
    return soc;
  })();

  return { socket };
};
